import React, { useContext, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import YoutubeComponent from "../OrientationVideo/components/YoutubeComponent";
import { AuthContext } from "context";
import { Button, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { updateStudentInfo } from "services/api/Repos/Student";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(componentStyles);

function Orientation() {
  const classes = useStyles();
  const { studentInfo, setStudentInfo } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [readCatalog, setReadCatalog] = useState(false);
  const [watchedVideo, setWatchedVideo] = useState(false);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const { data } = await updateStudentInfo({ read_orientation: 1 });
      setStudentInfo(data);
      setIsLoading(false);
      <Redirect to="/dashboard" />;
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}

      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} xl={9}>
            <YoutubeComponent video_id="KHEQTheKKKU" />
          </Grid>
          <Grid item xs={12} xl={9}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <a
              href="https://www.languagesystems.edu/public/student-catalog.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="primary"
                variant="contained"
                disabled={isLoading}
                style={{ marginTop: "1rem", marginRight: "1.5rem" }}
              >
                Student Catalog
              </Button>
            </a>

            {studentInfo.read_orientation !== 1 ? (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={readCatalog}
                      onChange={(e) => setReadCatalog(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="I have read the student catalog."
                  style={{ marginTop: "1rem" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={watchedVideo}
                      onChange={(e) => setWatchedVideo(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="I have watched the orientation video."
                  style={{ marginTop: "1rem" }}
                />
                <Box
                  onClick={handleClick}
                  sx={{
                    marginTop: "1rem",
                    padding: "0.5rem 1rem",
                    backgroundColor: "green",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    cursor: "pointer",
                    borderRadius: "4px",
                    '&:hover': {
                      backgroundColor: "darkgreen",
                    },
                    display: readCatalog && watchedVideo && !isLoading ? "block" : "none"
                  }}
                >
                  {!!isLoading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : (
                    "I acknowledge and accept the terms"
                  )}
                </Box>
              </>
              ) : (
                <Typography variant="h3" style={{ marginTop: "1rem" }}>
                  Thank you for watching the orientation video!
                </Typography>
              )}
          </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Orientation;

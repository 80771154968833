import React, { useContext, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Message from "@material-ui/icons/Message";
import { Link as RouterLink } from "react-router-dom";

// core components
import componentStyles from "assets/theme/components/header.js";

import { AuthContext } from "context";
import CardStats from "components/Cards/CardStats";
import actions from "redux/Messages/actions";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);

const Header = () => {
  const { studentInfo } = useContext(AuthContext);
  const classes = useStyles();
  const theme = useTheme();
  const { location_id } = useContext(AuthContext);

  const { unreadMessages, status } = useSelector(
    (state) => state.messageReducer
  );
  const dispatch = useDispatch();

  const getMessages = () => {
    dispatch({
      type: actions.GET_MESSAGES,
      payload: {},
    });
  };

  useEffect(() => {
    if (status === "loading") {
      return;
    } else if (status === "idle") {
      return getMessages();
    }
  }, [status]);

  const getBackgroundImgSrc = () => {
    switch (location_id) {
      case "1": {
        return "northeast.jpg";
      }
      case "2": {
        return "orange-county.jpg";
      }
      case "3": {
        return "downtown.jpg";
      }
      case "4": {
        return "torrance.jpg";
      }
      default: {
        return "hollywood.jpg";
      }
    }
  };

  return (
    <Box
      paddingTop="3rem"
      paddingBottom="8rem"
      alignItems="center"
      display="flex"
      className={classes.wrapperBox}
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL
        }/location-background/${getBackgroundImgSrc()})`,
      }}
      position="relative"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        className={classes.overlayBox}
      />
      <Container
        maxWidth={false}
        component={Box}
        classes={{ root: classes.containerRoot }}
      >
        {studentInfo.read_orientation === 1 ? (
          <Grid container>
            <Grid item xl={9} xs={12}>
              <RouterLink to={"/messages"} style={{ textDecoration: "none" }}>
                <CardStats
                  subtitle="Message Board"
                  title={
                    unreadMessages.length > 0
                      ? "You have unreaded messages"
                      : "Send a new message to LSI Staff"
                  }
                  icon={Message}
                  color={unreadMessages.length > 0 ? "bgError" : "bgSuccess"}
                  footer={
                    <>
                      <Box
                        component="span"
                        fontSize=".875rem"
                        color={
                          unreadMessages.length > 0
                            ? theme.palette.error.main
                            : theme.palette.success.main
                        }
                        marginRight=".5rem"
                        display="flex"
                        alignItems="center"
                      >
                        {unreadMessages.length}
                      </Box>
                      <Box component="span" whiteSpace="nowrap">
                        new messages from LSI staff
                      </Box>
                    </>
                  }
                />
              </RouterLink>
            </Grid>
          </Grid>
        ) : (
          <div>
            <Typography
              variant="h3"
              style={{
                color: "white",
                position: "absolute",
                marginTop: "-1.5rem",
              }}
            >
              Please watch the orientation video, read the student catalog, and then accept the terms below:
            </Typography>
          </div>
        )}
      </Container>
    </Box>
  );
};

export default Header;
